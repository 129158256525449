<template>
<div>
  <h6 v-if="loading">{{ $t('GENERAL.loading') }}...</h6>
  <table class="matrix" v-else>
    <thead>
      <td v-for="cat in categories" v-bind:key="cat.id"><h6>{{ cat.name }}</h6></td>
    </thead>
    <tbody>
      <tr v-for="cat in categories" v-bind:key="cat.id">
        <td :class="{ active: highlight.indexOf(ability.id) !== -1 }" v-for="ability in cat.abilities_mitre" v-bind:key="ability.id" @click="expand(cat.id, ability.id)">
          {{ ability.name }}

          <transition name="fade" mode="out-in">
            <div class="subabilities" v-if="expanded[cat.id] && expanded[cat.id][ability.id]">
              {{ ability.name }}<br>
            </div>
          </transition>
        </td>
      </tr>
    </tbody>
  </table>
</div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'MitreMatrix',
  props: {
    highlight: {
      type: Array,
      required: false,
      default: _ => []
    }
  },
  data () {
    return {
      expanded: {}
    }
  },
  methods: {
    ...mapActions({
      getAbilitiesMitre: 'staticInfo/getAbilitiesMitre'
    }),
    expand (catId, abId) {
      // TODO Check if the expand functionality is necessary
      // this.expanded[catId][abId] = !this.expanded[catId][abId]
      // this.$forceUpdate()
    }
  },
  computed: {
    ...mapGetters({
      categories: 'staticInfo/getAbilitiesMitre',
      loading: 'staticInfo/isLoading'
    })
  },
  created () {
    this.getAbilitiesMitre()
      .then(_ => {
        for (var cat in this.categories) {
          for (var ab in this.categories[cat].abilities_mitre) {
            if (Object.keys(this.expanded).indexOf(this.categories[cat].id.toString()) === -1) {
              this.expanded[this.categories[cat].id] = {}
            }
            this.expanded[this.categories[cat].id][this.categories[cat].abilities_mitre[ab].id] = false
          }
        }
      })
  }
}
</script>

<style lang="scss" scoped>

table {
  display: table;
}
table tr {
  display: table-cell;
  vertical-align: top;
}

table tr td {
  display: block;
}

table.matrix {
  thead td {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--primary-dark);
    min-width: 150px;
    text-align: center;
  }

  tbody td {
    padding: 8px;
    border: 1px solid lightgray;
    cursor: pointer;

    .subabilities {
      padding-left: 1rem;
    }

    &.active {
      border-width: 2px;
      border-color: $green;
      background-color: $green-light;
    }
  }
}
</style>
