<template>
<div class="mb-5 mitre-wrapper">
  <div class="cover-wrapper" v-show="!leftSide">
    <div class="cover"></div>
  </div>
  <div ref="wrapper" style="overflow-x: auto;" @scroll="handleScroll">
    <MitreMatrix ref="matrix" :highlight="highlight" />
  </div>
  <div class="cover-wrapper-right" v-show="!rightSide">
    <div class="cover"></div>
  </div>
</div>
</template>

<script>
import MitreMatrix from '@/components/MitreMatrix'

export default {
  name: 'WrapperForMitreMatrix',
  components: { MitreMatrix },
  props: {
    highlight: {
      type: Array,
      required: false,
      default: _ => []
    }
  },
  data () {
    return {
      scrollPosition: 0,
      leftSide: true,
      rightSide: false
    }
  },
  beforeDestroy () {
    this.$refs.wrapper.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (event) {
      // Any code to be executed when the window is scrolled
      this.scrollPosition = event.target.scrollLeft

      // Using a computed property for this doesn't work well
      this.leftSide = this.scrollPosition === 0
      this.rightSide = this.scrollPosition === event.target.scrollWidth - event.target.offsetWidth
    }
  },
  mounted () {
    // We are always on the left side, but depending on the Window width we may also be on the right side
    this.rightSide = this.$refs.wrapper.scrollLeft === this.$refs.wrapper.scrollWidth - this.$refs.wrapper.offsetWidth
  }
}
</script>

<style lang="scss" scoped>
.mitre-wrapper {
  // overflow-x: scroll;
  position: relative;
  height: 100%;

  .cover-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 150px;

    .cover {
      height: 100%;
      background: linear-gradient(to left, rgba(255, 255, 255, 0.001), white);
    }
  }

  .cover-wrapper-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 150px;
    right: 0;

    .cover {
      height: 100%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0.001), white);
    }
  }
}
</style>
