<template>
<div class="w100p">
  <apexchart
    :options="chartOptions"
    :series="series"
    type="radialBar"
  ></apexchart>
</div>

</template>

<script>
export default {
  name: 'LinearChart',
  props: {
    serieName: { type: String, required: false, default: '' },
    items: { type: Object, required: false, default: () => { return {} } }
  },
  data () {
    return {
      chartOptions: {}
    }
  },
  methods: {
    generateDateInterval (startDate, endDate) {
      var dates = []
      var currentDate = startDate
      var addDays = function (days) {
        var date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        date.setHours(0, 0, 0, 0)
        return date
      }
      while (currentDate <= endDate) {
        dates.push(currentDate)
        currentDate = addDays.call(currentDate, 1)
      }
      return dates
    }
  },
  computed: {
    series () {
      var oneWeekBefore = new Date()
      oneWeekBefore.setHours(0, 0, 0, 0)
      oneWeekBefore.setDate(oneWeekBefore.getDate() - 6)

      var today = new Date()
      today.setHours(0, 0, 0, 0)
      var dates = this.generateDateInterval(oneWeekBefore, today)

      return [
        {
          name: this.serieName,
          data: dates.map((date, index) => {
            return { x: date.getTime(), y: typeof this.items[date.getTime()] === 'undefined' ? 0 : this.items[date.getTime()] }
          })
        }
      ]
    }
  },
  mounted () {
    this.chartOptions = {
      colors: ['var(--primary)'],
      tooltip: {
        enabled: true,
        fillSeriesColor: false
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      grid: {
        row: {
          opacity: 0.5
        }
      },
      yaxis: {
        labels: {
          /**
          * Allows users to apply a custom formatter function to yaxis labels.
          *
          * @param { String } value - The generated value of the y-axis tick
          * @param { index } index of the tick / currently executing iteration in yaxis labels array
          */
          formatter: function (val, index) {
            return Math.floor(val)
          }
        }
      },
      xaxis: {
        type: 'datetime'
      }
    }
  }
}
</script>
