<template>
  <div>
    <div>
      <h3>
        {{ $t('PROFILE.my_formation') }}
      </h3>
    </div>
    <p>{{ $t('PROFILE.PROGRESS.your_progress_description') }}.</p>

    <h4 class="mt-5">
      {{ $t('PROFILE.your_activity') }}
    </h4>

    <p class="paragraph">{{ $t('PROFILE.your_activity_description') }}</p>

    <LinearChart :serie-name="$t('TERMS.pills')" :items="lastWeekCompletions" v-if="!isPillsLoading" />
    <div class="text-center py-4" v-else>
      <b-spinner  />
    </div>

    <!-- Courses -->

    <h4 class="mt-5">
      {{ $t('TERMS.courses') }}
    </h4>

    <!-- LOADING SKELETONS -->
    <b-row v-if="isCoursesLoading">
      <b-col sm="12" md="6"
       v-for="n in skeletonsNumber" v-bind:key="n"
      class="mb-3"
      >
      <ExpandableProgressCardSkeleton />
      </b-col>
    </b-row>
    <!-- END LOADING SKELETONS -->

    <b-row v-if="hasCourses">
      <b-col sm="12" md="6"
      v-for="(obj) in translatedFilteredCourses"
      v-bind:key="obj.name"
      class="mb-3"
      >
        <ExpandableProgressCard
        :id="obj.id"
        prefix="courses"
        :name="obj.name"
        :progress="calculateProgress(obj.score, obj.total_points)"
        :subitems="translateObjectArray(obj.children)"
        :to="{ name: 'courseDetail', params: { id: obj.id }}"
        />
      </b-col>

      <div class="text-center w-100" v-if="canShowMoreCourses">
        <b-button pill variant="primary" class="clickable" @click="showMoreCourses = !showMoreCourses">{{ $t(showMoreCourses ? 'GENERAL.show_less' : 'GENERAL.show_more')}}</b-button>
      </div>
    </b-row>

    <div v-else-if="!isCoursesLoading">
      <p>{{ $t('GENERAL.no_results') }}</p>
    </div>

    <!-- End Courses -->

    <!-- Careers -->

    <h4 class="mt-5" v-if="canSeeCareers">
      {{ $t('TERMS.careers') }}
    </h4>

    <!-- LOADING SKELETONS -->
    <b-row v-if="canSeeCareers && isCareersLoading">
      <b-col sm="12" md="6"
       v-for="n in skeletonsNumber" v-bind:key="n"
      class="mb-3"
      >
      <ExpandableProgressCardSkeleton />
      </b-col>
    </b-row>
    <!-- END LOADING SKELETONS -->

    <b-row v-if="canSeeCareers && hasCareers">
      <b-col sm="12" md="6"
      v-for="(obj) in filteredCareers"
      v-bind:key="obj.name"
      class="mb-3"
        @click="$router.push({ name: 'careerDetail', params: { id: obj.id} })"
      >
        <ExpandableProgressCard
        :id="obj.id"
        prefix="careers"
        :name="obj.name"
        :progress="calculateProgress(obj.score, obj.total_points)"
        :subitems="translateObjectArray(obj.children)"
        :to="{ name: 'careerDetail', params: { id: obj.id }}"
        />
      </b-col>

      <div class="text-center w-100" v-if="canShowMoreCareers">
        <b-button pill variant="primary" class="clickable" @click="showMoreCareers = !showMoreCareers">{{ $t(showMoreCareers ? 'GENERAL.show_less' : 'GENERAL.show_more')}}</b-button>
      </div>
    </b-row>

    <div v-else-if="canSeeCareers && !isCareersLoading">
      <p>{{ $t('GENERAL.no_results') }}</p>
    </div>
    <!-- End Careers -->

    <!-- Objectives -->

    <h4 class="mt-5">
      {{ $t('TERMS.objectives') }}
    </h4>

    <!-- LOADING SKELETONS -->
    <b-row v-if="isObjectivesLoading">
      <b-col sm="12" md="6"
       v-for="n in skeletonsNumber" v-bind:key="n"
      class="mb-3"
      >
      <ExpandableProgressCardSkeleton />
      </b-col>
    </b-row>
    <!-- END LOADING SKELETONS -->

    <b-row v-if="hasObjectives">
      <b-col sm="12" md="6"
      v-for="(obj) in translatedFilteredObjectives"
      v-bind:key="obj.name"
      class="mb-3"
      >
        <ExpandableProgressCard
        :id="obj.id"
        prefix="objectives"
        :name="obj.name"
        :progress="calculateProgress(obj.score, obj.total_points)"
        :subitems="translateObjectArray(obj.children)"
        :to="{ name: 'objectiveDetail', params: { id: obj.id }}"
        />
      </b-col>

      <div class="text-center w-100" v-if="canShowMoreObjectives">
        <b-button pill variant="primary" class="clickable" @click="showMoreObjectives = !showMoreObjectives">{{ $t(showMoreObjectives ? 'GENERAL.show_less' : 'GENERAL.show_more')}}</b-button>
      </div>
    </b-row>

    <div v-else-if="!isObjectivesLoading">
      <p>{{ $t('GENERAL.no_results') }}</p>
    </div>

    <!-- End Objectives -->

    <!-- Labs -->
    <h4 class="mt-4">
      {{ $t('TERMS.labs') }}
    </h4>

    <!-- LOADING SKELETONS -->
    <b-row v-if="isLabsLoading">
      <b-col sm="12" md="6"
       v-for="n in skeletonsNumber" v-bind:key="n"
      class="mb-3"
      >
      <ExpandableProgressCardSkeleton />
      </b-col>
    </b-row>
    <!-- END LOADING SKELETONS -->

    <b-row v-if="labs">
      <b-col sm="12" md="6"
      v-for="(obj) in translatedFilteredLabs"
      v-bind:key="obj.id"
      class="mb-3"
      >
        <ExpandableProgressCard
        :id="obj.id"
        prefix="labs"
        :name="obj.name"
        :progress="calculateProgress(obj.completed_machines, obj.vms.length)"
        :to="{ name: 'labDetail', params: { id: obj.id }}"
        />
      </b-col>

      <div class="text-center w-100" v-if="canShowMoreLabs">
        <b-button pill variant="primary" class="clickable" @click="showMoreLabs = !showMoreLabs">{{ $t(showMoreLabs ? 'GENERAL.show_less' : 'GENERAL.show_more')}}</b-button>
      </div>
    </b-row>

    <div v-else-if="!isLabsLoading">
      <p>{{ $t('GENERAL.no_results') }}</p>
    </div>

    <!-- End labs -->

    <!-- Pills table -->
    <h4 class="mt-4">
      {{ $t('TERMS.completed_pills') }}
    </h4>

    <PillsTable :items="translatedFilteredPills" :busy="isPillsLoading"/>

    <!-- End pills table -->

    <h4 class="mt-5">
      {{ $t('TERMS.categories_mitre') }}
    </h4>
    <p>{{ $t('PILLS.DETAIL.techniques_mitre_text') }}:</p>
    <span class="btn btn-primary" @click="$refs.modal.show()">{{ $t('ACTIONS.show') }}</span>

    <b-modal
      ref="modal"
      :title="$t('TERMS.techniques_mitre')"
      :ok-title="$t('ACTIONS.accept')"
      :cancel-title="$t('ACTIONS.cancel')"
      size="xl"
      dialog-class="w95p max-width-unset"
      hide-footer
    >
      <MitreMatrix :highlight="abilities" />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ExpandableProgressCard from '@/components/utils/cards/ExpandableProgressCard'
import ExpandableProgressCardSkeleton from '@/components/skeletons/ExpandableProgressCardSkeleton'
import MitreMatrix from '@/components/WrapperForMitreMatrix'
import PillsTable from '@/components/tables/Pills'

import LinearChart from '@/components/utils/LinearChart'

import ProgressMixin from '@/services/mixins/progress.mixins'

import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  mixins: [ProgressMixin, i18nmixin],
  components: { ExpandableProgressCard, ExpandableProgressCardSkeleton, MitreMatrix, LinearChart, PillsTable },
  data () {
    return {
      lastWeekCompletions: {},

      showMoreCareers: false,
      showMoreObjectives: false,
      showMoreCourses: false,
      showMoreLabs: false
    }
  },
  methods: {
    ...mapActions({
      getCareers: 'careers/getCareers',
      getObjectivesFirstLevel: 'objectives/getObjectivesFirstLevel',
      getCompletedCategoriesMitre: 'categories/getCompletedCategoriesMitre',
      getLabs: 'labs/getLabs',
      getPills: 'pills/getPills',
      getCourses: 'academy/getCourses',
      getLastWeekCompletedPills: 'user/getLastWeekCompletedPills'
    }),
    withoutTime (date) {
      var d = new Date(date)
      d.setHours(0, 0, 0, 0)
      return d
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/currentUser',
      labs: 'labs/getLabs',
      careers: 'careers/getCareers',
      objectives: 'objectives/getObjectivesFirstLevel',
      pills: 'pills/getPills',
      courses: 'academy/getCourses',
      completedCategoriesMitre: 'categories/getCompletedCategoriesMitre',
      isUserLoading: 'user/isLoading',
      isObjectivesLoading: 'objectives/isLoading',
      isCategoriesLoading: 'categories/isLoading',
      isCareersLoading: 'careers/isLoading',
      isLabsLoading: 'labs/isLoading',
      isPillsLoading: 'pills/isLoading',
      isCoursesLoading: 'academy/isLoading',

      canSeeCareers: 'config/canSeeCareers'
    }),
    skeletonsNumber: _ => new Array(6),
    abilities () { return Object.keys(this.completedCategoriesMitre).map(i => parseInt(i)) },

    hasLabs () { return typeof this.labs !== 'undefined' && this.labs.length > 0 },
    translatedFilteredLabs () { return this.translateObjectArray(this.filteredLabs) },
    filteredLabs () { return this.showMoreLabs ? this.labs : this.labs.slice(0, 6) },
    canShowMoreLabs () { return this.labs.length > 6 },

    hasCareers () { return typeof this.careers !== 'undefined' && this.careers.length > 0 },
    filteredCareers () { return this.showMoreCareers ? this.careers : this.careers.slice(0, 6) },
    canShowMoreCareers () { return this.careers.length > 6 },

    hasObjectives () { return typeof this.objectives !== 'undefined' && this.objectives.length > 0 },
    translatedFilteredObjectives () { return this.translateObjectArray(this.filteredObjectives) },
    filteredObjectives () { return this.showMoreObjectives ? this.objectives : this.objectives.slice(0, 6) },
    canShowMoreObjectives () { return this.objectives.length > 6 },

    hasCourses () { return typeof this.courses !== 'undefined' && this.courses.length > 0 },
    translatedFilteredCourses () { return this.translateObjectArray(this.filteredCourses) },
    filteredCourses () { return this.showMoreCourses ? this.courses : this.courses.slice(0, 6) },
    canShowMoreCourses () { return this.courses.length > 6 },

    completedPills () { return this.pills.filter(pill => pill.student_pill_completion.completion_status === 'completed') },

    translatedFilteredPills () {
      return this.translateObjectArray(this.completedPills)
    }
  },
  mounted () {
    this.getCompletedCategoriesMitre()
    this.getCareers()
    this.getCourses()
    this.getObjectivesFirstLevel()
    this.getPills()
    this.getLabs()
    this.getLastWeekCompletedPills()
      .then(data => data.map(pill => this.withoutTime(pill.student_pill_completion.date_finish).getTime()))
      .then(data => data.reduce(function (acc, curr) {
        if (typeof acc[curr] === 'undefined') {
          acc[curr] = 1
        } else {
          acc[curr] += 1
        }

        return acc
      }, {}))
      .then(data => {
        this.lastWeekCompletions = data
      })
  }
}
</script>
