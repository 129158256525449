<template>
  <Card no-shadow>
    <template v-slot:title>
      <b-row align-v="center" tabindex="-1">
        <div style="width: 98px; padding-left: 12px;">
        <RadialProgress :progress="progress"></RadialProgress>
        </div>
        <div style="flex: 1;">
          <span class="font-weight-semibold" :class="{ 'hover-underline': hasTo, clickable: hasTo }" @click="goTo">{{ name }}</span>
        </div>
        <div class="t-right hover-underline no-outline ml-2 mr-2" v-if="hasSubitems" v-b-toggle="`obj-${prefix}-${id}`" @click="expanded = !expanded">
          <span>{{ showMoreText }}<b-icon icon="chevron-double-right" scale="0.8" variant="primary-dark" /></span>
        </div>
      </b-row>
    </template>
    <template v-slot:content v-if="hasSubitems">
      <b-collapse :id="`obj-${prefix}-${id}`" class="pt-3">
        <div  v-for="(subitem, index) in subitems" v-bind:key="subitem.id">
          <span>{{ subitem.name }}</span>
          <b-progress :value="calculateProgress(subitem.score, subitem.total_points)" height="10px" :class="{ 'mb-3': index < subitems.length - 1 }" />
        </div>
      </b-collapse>
    </template>
  </Card>
</template>

<script>
import RadialProgress from '@/components/utils/RadialProgress'

import ProgressMixin from '@/services/mixins/progress.mixins'

export default {
  components: { RadialProgress },
  mixins: [ProgressMixin],
  props: {
    name: { type: String, required: true },
    id: { required: true },
    prefix: { required: false, default: '' },
    progress: { required: true },
    subitems: { type: Array, required: false, default: () => [] },
    to: { type: Object, required: false, default: () => { return {} } }
  },
  data () {
    return {
      expanded: false
    }
  },
  methods: {
    goTo () {
      if (!this.hasTo) return

      this.$router.push(this.to)
    }
  },
  computed: {
    hasTo () { return this.to !== {} },
    hasSubitems () { return this.subitems.length > 0 },

    showMoreText () { return this.$t(this.expanded ? 'GENERAL.show_less' : 'GENERAL.show_more') }
  }

}
</script>

<style>

</style>
