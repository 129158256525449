<template>
  <div class="progress-container ">
    <div class="progress-radial" :data-percentage="progress" :class="{ inverse: inverse }">
      <span class="progress-left">
        <span class="progress-bar"></span>
      </span>
      <span class="progress-right">
        <span class="progress-bar"></span>
      </span>
      <div class="progress-value">
        <div :class="{ 'text-inverse-primary': inverse }">
          {{ progress }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../../assets/scss/_progress.scss'

export default {
  props: {
    progress: {
      required: false,
      default: 0
    },
    inverse: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-radial.inverse {
  &:after {
    border-color: var(--secondary);
  }

  .progress-left .progress-bar {
    border-color: white;
  }

  .progress-right .progress-bar {
    border-color: white;
  }
}
</style>
