<template>
  <b-card>
    <div class="flex" style="display: flex;">
      <b-skeleton type="avatar" class="mr-3" width="64px" height="64px"></b-skeleton>
      <div class="flex flex-column justify-content-center" style="flex: 1;">
        <b-skeleton width="60%"></b-skeleton>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'ExpandableProgressCardSkeleton'
}
</script>
